<!--
 * @Description: 操作日志详细列表 根据code 和 primary_key 查找
 * @Author: 琢磨先生
 * @Date: 2023-08-20 13:37:15
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-21 10:17:59
-->
<template>
  <div style="flex-grow: 1;height: 0px;" v-if="code">
    <el-scrollbar v-if="tableData.counts > 0">
      <el-timeline>
        <el-timeline-item
          placement="top"
          v-for="(item, index) in tableData.data"
          :key="index"
          :timestamp="`${item.create_at} ${item.emp_name}`"
        >
          <div
            style="white-space: pre;word-wrap: break-word;white-space: pre-wrap; background-color:#f5f5f5; padding:10px 15px;font-size: 12px; "
            v-html="item.description"
          ></div>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>
    <el-empty v-else description="暂无数据" />
  </div>
  <el-pagination
    v-if="tableData.counts > 0"
    @size-change="pageSizeChange"
    @current-change="pageCurrentChange"
    :page-size="query.pageSize"
    layout="total, prev, pager, next"
    :total="tableData.counts"
  ></el-pagination>
</template>

<script>
export default {
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 20,
      },
      tableData: {
        counts: 0,
      },
    };
  },
  props: ["code", "primaryKey"],
  watch: {
    primaryKey: {
      handler() {
        if (this.code && this.primaryKey) {
          this.query.primary_key = `${this.primaryKey}`;
          this.query.module_code = `${this.code}`;
          this.loadLog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 加载日志
     */
    loadLog() {
      this.$http.post("seller/v1/OperateLog", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadLog();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadLog();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>