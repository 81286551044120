<template>
  <el-drawer title="操作日志" v-model="visibleDrawer" custom-class="operate_log_drawer" size="800px" @closed="onClose">
    <operate-log :code="code" :primaryKey="primaryKey"></operate-log>
  </el-drawer>
</template>

<script>
import OperateLog from "./operate_log_list.vue";
export default {
  components: { OperateLog },
  data() {
    return {
      visibleDrawer: false,
    };
  },
  props: ["code", "primaryKey"],
  emits: ["close"],
  watch: {
    primaryKey: {
      handler() {
        if (this.code && this.primaryKey) {
          this.visibleDrawer = true;
          //   this.query.primary_key = this.primaryKey;
          //   this.query.module_code = this.code;
          //   this.loadLog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style  >
 /* .operate_log_drawer {
    display: flex;
 } */
   .operate_log_drawer .el-drawer__body{
        display: flex;
        flex-direction: column;
    }
</style>