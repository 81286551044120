<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-12-24 13:10:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 10:00:59
-->
<template>
  <el-button type="success" icon="UploadFilled" round size="small" @click="onOpen" v-power="'seller/v1/expense/excel/import'">Excel导入</el-button>
  <el-dialog
    title="导入费用数据"
    v-model="dialogVisible"
    width="700px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert type="info" :closable="false" title>
      <div class="flex-row item-center">
        请按格式编辑Excel文件后上传、只支持xlsx文件。
        <el-link type="primary" :underline="false" @click="this.tempDialogVisible = true">下载</el-link>文件格式模板
      </div>
    </el-alert>
    <div style="flex-grow:1;">
      <el-upload
        ref="uploadRef"
        class="upload-demo"
        :auto-upload="false"
        :http-request="upload"
        accept=".xls, .xlsx"
        :limit="1"
        drag
        action
      >
        <el-icon class="el-icon--upload">
          <upload-filled />
        </el-icon>
        <div class="el-upload__text">
          拖拽到此或者
          <em>点击选择</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">请选择xlsx文件</div>
        </template>
      </el-upload>
    </div>
    <template #footer>
      <el-button type="primary" @click="excelImport" :loading="uploading">立即导入</el-button>
    </template>
  </el-dialog>

  <el-dialog
    title="下载模板选项"
    v-model="tempDialogVisible"
    draggable
    width="560px"
    :close-on-click-modal="false"
  >
    <el-form v-model="tempForm" label-width="100px">
      <el-form-item label="订单类型">
        <el-radio-group v-model="tempForm.order_type">
          <el-radio :label="-1">全部</el-radio>
          <el-radio :label="0">普通订单</el-radio>
          <el-radio :label="1">试住单</el-radio>
          <el-radio :label="2">自住单</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="费用项">
        <el-select v-model="tempForm.item_ids" multiple clearable style="width: 360px">
          <el-option v-for="item in expenseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onStartDown" :loading="downloading">确定下载</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import cfg from "@/config/config.js";
import fileDownload from "js-file-download";

export default {
  data() {
    return {
      dialogVisible: false,
      tempDialogVisible: false,
      downloading: false,
      uploading: false,
      form: {},
      rules: {},
      tempForm: {
        item_ids: [],
        //下载模板选项选择的订单类型，-1：全部
        order_type: -1,
      },
    };
  },
  props: ["expenseList"],
  emits: ["success"],
  created() {},
  methods: {
    /**
     * 确定下载模板
     */
    onStartDown() {
      this.downloading = true;
      this.$http
        .post("seller/v1/expense/excel/temp", this.tempForm, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "费用导入.xlsx");
          this.tempDialogVisible = false;
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    /**
     *
     */
    onOpen() {
      this.dialogVisible = true;
    },

    /**
     * 从excel导入房东
     */
    excelImport() {
      this.$refs.uploadRef.submit();
    },

    /**
     * 上传文件导入
     */
    upload(ctx) {
      this.uploading = true;
      var formData = new FormData();
      formData.append("file", ctx.file);
      this.$http
        .post("seller/v1/expense/excel/import", formData)
        .then((res) => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$emit("success");
            this.$refs.uploadRef.clearFiles();
          }
        })
        .finally(() => {
          this.uploading = false;
        });
    },
  },
};
</script>

<style  scoped>
</style>