<!--
 * @Description: 房间费用支出
 * @Author: 琢磨先生
 * @Date: 2022-06-28 13:59:27
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-02-07 08:44:22
-->

<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="备注内容"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          :props="props"
          clearable
          style="width: 200px"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="费用项">
        <el-select
          v-model="query.itemIds"
          multiple
          clearable
          style="width: 300px"
        >
          <el-option
            v-for="item in expenseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select v-model="query.order_type" clearable style="width: 160px">
          <el-option
            v-for="item in enums.order_types"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付日期">
        <el-date-picker
          v-model="query.dates"
          type="daterange"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="录入日期">
        <el-date-picker
          v-model="query.createDates"
          type="daterange"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="删除?">
        <el-select
          v-model="query.is_del"
          placeholder=""
          :clearable="true"
          style="width: 100px"
        >
          <el-option label="正常" value="false"></el-option>
          <el-option label="已删除" value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onEdit()"
        round
        icon="plus"
        v-if="is_can_edit"
        >新增</el-button
      >
      <add-everyday
        :expenseList="expenseList"
        @success="onSearch"
      ></add-everyday>
      <span style="margin: 0 12px">
        <excel-import
          :expenseList="expenseList"
          @success="onSearch"
        ></excel-import>
      </span>
      <el-button
        type="danger"
        size="small"
        round
        icon="Delete"
        @click="onBatchDelete"
        :disabled="selection_list.length == 0"
        :loading="deleting"
        v-power="'seller/v1/expense/record/batchDel'"
        >批量删除</el-button
      >
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <div class="total_wrp">
      <span>总额(元)：￥{{ totalModel.decimal_total_amount }}</span>
      <span>成本(元)：￥{{ totalModel.decimal_cost_amount }}</span>
      <span>业主(元)：￥{{ totalModel.decimal_landlord_amount }}</span>
      <span>商户(元)：￥{{ totalModel.decimal_business_amount }}</span>
      <span>商户实际(元)：￥{{ totalModel.decimal_business_cost_amount }}</span>
      <span
        >商户利润(元)：￥{{ totalModel.decimal_business_profit_amount }}</span
      >
    </div>
    <el-table
      :data="tableData.data"
      @sort-change="onSort"
      border
      row-key="id"
      :row-style="handleRowStyle"
      @selection-change="tableSelectionChange"
    >
      <!-- <el-table-column prop="id" label="编号" width="80"></el-table-column> -->
      <el-table-column
        label
        type="selection"
        reserve-selection
      ></el-table-column>
      <el-table-column
        prop="store.landlord.name"
        label="业主"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="store.house_no"
        label="房号"
        min-width="200"
      ></el-table-column>
      <el-table-column
        prop="expense_item_name"
        label="费用名称"
        width="150"
      ></el-table-column>

      <el-table-column
        prop="order_type_text"
        label="订单类型"
        width="140"
      ></el-table-column>
      <el-table-column
        label="费用日期"
        prop="pay_date"
        width="120"
      ></el-table-column>
      <el-table-column label="总额(元)" width="140">
        <template #default="scope"
          >￥{{ scope.row.decimal_total_amount }}</template
        >
      </el-table-column>
      <el-table-column label="实际成本(元)" width="140">
        <template #default="scope">
          <span v-if="scope.row.decimal_cost_amount > 0"
            >￥{{ scope.row.decimal_cost_amount }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="业主占比(元)" width="140">
        <template #default="scope"
          >￥{{ scope.row.decimal_landlord_amount }}</template
        >
      </el-table-column>
      <el-table-column prop label="商户占比(元)" width="140">
        <template #default="scope"
          >￥{{ scope.row.decimal_business_amount }}</template
        >
      </el-table-column>
      <el-table-column prop label="商户实付(元)" width="140">
        <template #default="scope"
          >￥{{ scope.row.decimal_business_cost_amount }}</template
        >
      </el-table-column>

      <el-table-column prop="number" label="数量" width="100"></el-table-column>
      <el-table-column label="单价" width="120">
        <template #default="scope">
          <div v-if="scope.row.decimal_unit_price">
            {{ scope.row.decimal_unit_price }}{{ scope.row.expense_item_unit }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="备注"
        min-width="240"
      ></el-table-column>
      <el-table-column
        prop="emp.chs_name"
        label="创建人"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="create_at"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column label="删除？" width="180">
        <template #default="scope"
          >{{ scope.row.is_deleted ? "已删除" : "" }} /
          {{ scope.row.deleted_at }}</template
        >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button
            type="primary"
            link
            icon="edit"
            size="small"
            @click="onEdit(scope.row)"
            v-if="
              !scope.row.is_deleted &&
              is_can_edit &&
              (scope.row.status === 0 || scope.row.status === 4)
            "
            >修改</el-button
          >
          <el-button
            type="primary"
            link
            icon="view"
            size="small"
            @click="log_primary_key = scope.row.id"
            v-if="
              !scope.row.is_deleted &&
              is_can_edit &&
              (scope.row.status === 0 || scope.row.status === 4)
            "
            >查看日志</el-button
          >
          <el-popconfirm
            title="确定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="!scope.row.is_deleted && is_can_del"
          >
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <edit-expense
    :id="edit_id"
    :visible="visibleDialog"
    @close="this.visibleDialog = false"
    @success="loadData"
  ></edit-expense>

  <operate-log
    code="6001"
    :primaryKey="log_primary_key"
    @close="log_primary_key = ''"
  ></operate-log>
</template>

<script>
import expense_api from "@/http/expense_api";
import huxing_api from "@/http/huxing_api";
import common_api from "@/http/common_api";
import EditExpense from "./edit_expense.vue";
import AddEveryday from "./add_everyday.vue";
import ExcelImport from "./excel_import.vue";
import OperateLog from "@/views/components/operate_log_dra";

export default {
  components: {
    EditExpense,
    AddEveryday,
    ExcelImport,
    OperateLog,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      title: "",
      //显示导入弹框
      visibleDialog: false,
      saving: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //修改的对象id
      edit_id: "",
      //
      enums: {},
      form: {},
      rules: {
        decimal_total_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        expense_item_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        storeIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //费用项目
      expenseList: [],
      storeList: [],
      //当前房源的占比
      storeExpense: null,
      //房型options
      huxing_list: [],
      props: {
        label: "name",
        value: "id",
      },
      //统计对象
      totalModel: {},
      //选中的数据
      selection_list: [],
      //可以编辑
      is_can_edit: false,
      is_can_del: false,
      //日志key
      log_primary_key: "",
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.expenseList = res.data;
      }
    });

    huxing_api.get_stores().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            id: x.id,
            name: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              id: item.id,
              name: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });

    this.loadData();
    this.loadTotal();

    this.is_can_edit = this.$power("seller/v1/expense/record/edit");
    this.is_can_del = this.$power("seller/v1/expense/record/del");
  },
  methods: {
    /**
     * 单价、数量变动计算总额
     */
    priceOrNumberChange() {
      if (
        this.form.number &&
        this.form.decimal_unit_price &&
        !isNaN(this.form.decimal_unit_price) &&
        !isNaN(this.form.number)
      ) {
        this.form.decimal_total_amount =
          parseFloat(this.form.decimal_unit_price) * parseInt(this.form.number);
      } else {
        this.form.decimal_total_amount = 0;
      }
    },

    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.query.store_id = "";
      if (this.query.storeIds) {
        this.query.store_id =
          this.query.storeIds[this.query.storeIds.length - 1];
      }
      console.log(this.query);
      this.$http.post("seller/v1/expense/records", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 加载统计数据
     */
    loadTotal() {
      this.$http
        .post("seller/v1/expense/records/total", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.totalModel = res.data;
          }
        });
    },

    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
      this.loadTotal();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 审核房费支出
     */
    onEdit(item) {
      this.edit_id = "";
      if (item) {
        this.edit_id = item.id;
      }
      // this.title = item ? "修改" : "添加费用支出";
      // this.form = {
      //   order_type: 0,
      // };
      // if (item) {
      //   this.form.id = item.id;
      //   this.form.storeIds = [item.store.group_id, item.store.id];
      //   this.form.expense_item_id = item.expense_item_id;
      //   this.form.order_type = item.order_type;
      //   this.form.unit_price = item.unit_price;
      //   this.form.number = item.number;
      //   this.form.decimal_total_amount = item.decimal_total_amount;
      //   this.form.remark = item.remark;
      //   this.form.pay_date = item.pay_date;
      //   this.form.pic_urls = item.pic_urls;
      //   this.form.decimal_cost_amount = item.decimal_cost_amount;

      //   this.form.house_no = item.store.house_no;
      //   this.changeExpense();
      // }
      this.visibleDialog = true;
    },

    /**
     * 审核费用支出
     * @param {*} item
     */
    onCheck(item) {
      console.log(item);
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("seller/v1/expense/record/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },
    /**
     * 费用项选择更改
     */
    changeExpense() {
      this.form.expense = this.expenseList.find(
        (o) => o.id == this.form.expense_item_id
      );
      this.calcAA();
    },

    /**
     * 房号选择更改
     */
    changeStore() {
      this.calcAA();
    },

    /**
     * 计算
     */
    calcAA() {
      this.$http
        .post("seller/v1/expense/record/calc", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.form.tip = `(房东占比：${res.data.decimal_landlord_amount}元、商户：${res.data.decimal_business_amount}元)`;
          }
        });
    },

    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      if (!this.form.id) {
        if (!this.form.storeIds) {
          return;
        }
        if (this.form.storeIds.length <= 1) {
          this.$message.error("请选择房间");
          return;
        }
      }
      this.form.house_store_id =
        this.form.storeIds[this.form.storeIds.length - 1];
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/expense/record/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 审核状态回调
     */
    checkChange() {
      this.onSearch();
    },

    /**
     * 选中更改
     */
    tableSelectionChange(nodes) {
      this.selection_list = nodes;
    },

    /**
     * 批量删除
     */
    onBatchDelete() {
      if (this.selection_list.length > 0) {
        this.$confirm("确定要删除选中的数据？", "温馨提示", {}).then(() => {
          this.deleting = true;
          this.$http
            .post(
              "seller/v1/expense/record/batchDel",
              this.selection_list.map((x) => x.id)
            )
            .then((res) => {
              if (res.code == 0) {
                this.selection_list = [];
                this.$message.success(res.msg);
                this.loadData();
              }
            })
            .finally(() => {
              this.deleting = false;
            });
        });
      }
    },

    /**
     *
     */
    handleRowStyle(item) {
      if (item.row.is_deleted) {
        return "color:#f56c6c;";
      }
    },
  },
};
</script>

<style scoped>
.total_wrp {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.total_wrp span {
  margin-right: 40px;
}
</style>
